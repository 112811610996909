<template>

  <b-row class="match-height">
  <!-- <b-col cols="3">
    <b-form-select v-model="selected" :options="options"></b-form-select>
  </b-col> -->
  <b-col cols="12" style="margin-bottom:20px">
    <b-row>
      <b-col cols="3" v-if="this.pengguna.hak_akses == 'mabes'">
    <b-form-select v-model="selectedProv" :options="optionsProv" @change="getPolda(selectedProv);getChart(selectedProv,'provinsi');tipe_wilayah = 'provinsi';id_wilayah = selectedProv"></b-form-select>
  </b-col>
  <b-col cols="3" v-if="this.pengguna.hak_akses == 'mabes' || this.pengguna.hak_akses == 'polda'">
    <b-form-select v-model="selectedPolda" :options="options" @change="getPolres(selectedPolda);getChart(selectedPolda,'kabupaten');tipe_wilayah = 'kabupaten';id_wilayah = selectedPolda"></b-form-select>
  </b-col>
  <b-col cols="3">
    <b-form-select v-model="selectedPolres" :options="optionsPolres" @change="getChart(selectedPolres,'kecamatan');tipe_wilayah = 'kecamatan';id_wilayah = selectedPolres"></b-form-select>
  </b-col>
  <b-col cols="3">
    <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        :href="'https://sembako-backend.denican.id/stok_export?tipe_wilayah='+tipe_wilayah+'&id_wilayah='+id_wilayah"
        
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        <span class="align-middle">Download Excel</span>
      </b-button>
  </b-col>
  </b-row>
  </b-col>
  <b-col cols="12">
    <b-card title="Ketersediaan Komoditi per Hari ini" >
      <vue-apex-charts ref="sample" type="bar" height="350" :options="chartOptions" :series="series" @click="clickHandler"></vue-apex-charts>
      <div><b-button variant="primary" style="margin-bottom:5px"></b-button> Lebih: Ada diseluruh toko dan gudang </div>
      <div><b-button variant="success" style="margin-bottom:5px"></b-button> Cukup: Ada diseluruh toko</div>
      <div><b-button variant="warning" style="margin-bottom:5px"></b-button> Kurang: Tidak ada dilebih dari 5 toko</div>
      <div><b-button variant="danger"></b-button> Kosong: Tidak ada disemua toko</div>
    </b-card>
  </b-col>

  <b-col cols="12">
    <b-card v-if="namaKomoditi != null">
      <h3>Pemantauan Ketersediaan Komoditi <strong> {{this.namaKomoditiAsli}} </strong> dalam 2 minggu terakhir</h3>
      <vue-apex-charts ref="sampleDetail" type="bar" height="350" :options="chartOptionsDetail" :series="seriesDetail" ></vue-apex-charts>
      <div><b-button variant="primary" style="margin-bottom:5px"></b-button> Lebih: Ada diseluruh toko dan gudang </div>
      <div><b-button variant="success" style="margin-bottom:5px"></b-button> Cukup: Ada diseluruh toko</div>
      <div><b-button variant="warning" style="margin-bottom:5px"></b-button> Kurang: Tidak ada dilebih dari 5 toko</div>
      <div><b-button variant="danger"></b-button> Kosong: Tidak ada disemua toko</div>
    </b-card>
  </b-col>

  </b-row>
</template>

<script>
import { BRow, BCol, BLink, BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,BFormSelect,BButton,BBadge} from 'bootstrap-vue'
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    VueApexCharts,
    BRow,
    BCol,
    BLink,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardSubTitle,
    BFormSelect,
    BButton,
    BBadge,
    Vue
  },
  data() {
    return {
      url: localStorage.getItem('baseapi'),
      pengguna: JSON.parse(localStorage.getItem('userData')),
      warna : [],
      namaKomoditi: null,
      namaKomoditiAsli: null,
      selectedPolda:null,
      selectedProv:null,
      selectedPolres:null,
      tipe_wilayah: null,
      id_wilayah:null,
      downloadExcell:'',
      optionsProv: [
          { value: null, text: 'Please select an option' }
      ],
      options: [
          { value: null, text: 'Please select an option' }
      ],
      optionsPolres: [
          { value: null, text: 'Please select an option' }
      ],
      
      series: [{
            data: []
      }],
      seriesDetail: [{
            data: []
      }],
      chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
              events: {
                click: function(chart, w, e) {
                  // console.log(chart, w, e)
                }
              }
            },
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            colors: [],
            legend: {
              show: false
            },
            yaxis: {
              show: false,
            },
            xaxis: {
              categories: [],
              labels: {
                style: {
                  colors: [],
                  fontSize: '12px'
                }
              }
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: (y) => {
                  if (y == 1) {
                    return "Kosong";
                  }else if (y == 2) {
                    return "Kurang";
                  }else if (y == 3) {
                    return "Cukup";
                  }else if (y == 4) {
                    return "Lebih";
                  }
                },
              }
            }
      },
      chartOptionsDetail: {
            chart: {
              height: 350,
              type: 'bar',
              events: {
                click: function(chart, w, e) {
                  // console.log(chart, w, e)
                }
              }
            },
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            colors: [],
            legend: {
              show: false
            },
            yaxis: {
              show: false,
            },
            xaxis: {
              categories: [],
              labels: {
                style: {
                  colors: [],
                  fontSize: '12px'
                }
              }
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: (y) => {
                  if (y == 1) {
                    return "Kosong";
                  }else if (y == 2) {
                    return "Kurang";
                  }else if (y == 3) {
                    return "Cukup";
                  }else if (y == 4) {
                    return "Lebih";
                  }
                },
              }
            }
      },
    }
  },
  
  methods:{
    async loadData(){
      await axios.get(this.url+'wilayah/provinsi')
      .then(response =>{
        var dataProvinsi = []
        dataProvinsi = response.data.data

        for(let i = 0; i<dataProvinsi.length;i++){
          this.optionsProv.push({
            value: dataProvinsi[i].id,
            text: dataProvinsi[i].nama
          })
        }
        
      })
    },
    async getPolda(id){
      this.options = []
      this.optionsPolres = []
      this.selectedPolda = null
      this.selectedPolres = null
      await axios.get(this.url+'wilayah/kabupaten?id_prov='+id)
      .then(response =>{
        var dataPolda = []
        dataPolda = response.data.data

        for(let i = 0; i<dataPolda.length;i++){
          this.options.push({
            value: dataPolda[i].id,
            text: dataPolda[i].nama
          })
        }
        
      })
    },
    async getPolres(id){
      this.optionsPolres = []
      this.selectedPolres = null
      await axios.get(this.url+'wilayah/kecamatan?id_kab='+id)
      .then(response =>{
        console.log(response)
        var dataPolres = []
        dataPolres = response.data.data

        for(let i = 0; i<dataPolres.length;i++){
          this.optionsPolres.push({
            value: dataPolres[i].id,
            text: dataPolres[i].nama
          })
        }
        
      })
    },
    async getChart(id,type){
      await axios.get(this.url+'stok_chart/ketersediaan?tipe_wilayah='+type+'&id_wilayah='+id, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.namaKomoditi = null
        this.series[0].data = []
        var ser = this.chartOptions.xaxis.categories;
        while (ser.length) {
          ser.pop();
        }

        var warnaChart = this.chartOptions.colors;
        while (warnaChart.length) {
          warnaChart.pop();
        }

        //this.chartOptions.xaxis.labels.style.colors = []
        //this.$data.chartOptions.colors = []

        var dataWarna =[]
        var kategori =[]
        var dataSeries = []
        
        dataSeries = response.data.data.nilai
        dataWarna = response.data.data.ketersediaan
        kategori = response.data.data.jenis

        // this.chartOptions.xaxis.categories = kategori
        // this.series[0].data = dataSeries
        
        for (let i = 0; i < kategori.length; i++) {
            this.$data.chartOptions.xaxis.categories.push(kategori[i]);
        }

        for (let i = 0; i < dataWarna.length; i++) {
          if(dataWarna[i] === "Lebih"){
            this.$data.chartOptions.xaxis.labels.style.colors.push('#3498db');
            this.$data.chartOptions.colors.push('#3498db');
          }else if(dataWarna[i] === "Cukup"){
            this.$data.chartOptions.xaxis.labels.style.colors.push('#2ecc71');
            this.$data.chartOptions.colors.push('#2ecc71');
          }else if(dataWarna[i] === "Kurang"){
            this.$data.chartOptions.xaxis.labels.style.colors.push('#ff9f43');
            this.$data.chartOptions.colors.push('#ff9f43');
          }else{
            this.$data.chartOptions.xaxis.labels.style.colors.push('#e74c3c');
            this.$data.chartOptions.colors.push('#e74c3c');
          }
            
        }
        this.$refs.sample.updateOptions({
            series: [{
              data: dataSeries
            }],
        })
      })
      .catch( error => {
        this.namaKomoditi = null
        this.series[0].data = []
        var ser = this.chartOptions.xaxis.categories;
        while (ser.length) {
          ser.pop();
        }

        var warnaChart = this.chartOptions.colors;
        while (warnaChart.length) {
          warnaChart.pop();
        }
        this.$refs.sample.updateOptions({
            series: [{
              data:[]
            }],
        })
      })
    },
    async clickHandler(event, chartContext, config){
      console.log(config.dataPointIndex)
      if(config.dataPointIndex == 0){
          this.namaKomoditi = "beras"
          this.namaKomoditiAsli = "Beras"
        }else if(config.dataPointIndex == 1){
          this.namaKomoditi = "minyak_goreng"
          this.namaKomoditiAsli = "Minyak Goreng"
        }else if(config.dataPointIndex == 2){
          this.namaKomoditi = "telur"
          this.namaKomoditiAsli = "Telur"
        }else if(config.dataPointIndex == 3){
          this.namaKomoditi = "gula"
          this.namaKomoditiAsli = "Gula"
        }else if(config.dataPointIndex == 4){
          this.namaKomoditi = "daging_ayam"
          this.namaKomoditiAsli = "Daging Ayam"
        }else if(config.dataPointIndex == 5){
          this.namaKomoditi = "daging_sapi"
          this.namaKomoditiAsli = "Daging Sapi"
        }else if(config.dataPointIndex == 6){
          this.namaKomoditi = "kedelai"
          this.namaKomoditiAsli = "Kedelai"
        }else if(config.dataPointIndex == 7){
          this.namaKomoditi = "cabe_merah"
          this.namaKomoditiAsli = "Cabe Merah"
        }else if(config.dataPointIndex == 8){
          this.namaKomoditi = "bawang_merah"
          this.namaKomoditiAsli = "Bawah Merah"
        }

        await axios.get(this.url+'stok_chart/ketersediaan/2minggu?tipe_wilayah='+this.tipe_wilayah+'&id_wilayah='+this.id_wilayah+'&komoditi='+this.namaKomoditi)
        .then(response => {
        console.log(response)
        this.seriesDetail[0].data = []
        var ser = this.chartOptionsDetail.xaxis.categories;
        while (ser.length) {
          ser.pop();
        }

        var warnaChart = this.chartOptionsDetail.colors;
        while (warnaChart.length) {
          warnaChart.pop();
        }

        //this.chartOptions.xaxis.labels.style.colors = []
        //this.$data.chartOptions.colors = []

        var dataWarna =[]
        var kategori =[]
        var dataSeries = []
        
        this.$data.seriesDetail[0].data = response.data.data.nilai
        dataWarna = response.data.data.ketersediaan
        kategori = response.data.data.tanggal

        // this.chartOptions.xaxis.categories = kategori
        // this.series[0].data = dataSeries
        
        for (let i = 0; i < kategori.length; i++) {
            this.$data.chartOptionsDetail.xaxis.categories.push(kategori[i]);
        }

        for (let i = 0; i < dataWarna.length; i++) {
          if(dataWarna[i] === "Lebih"){
            this.$data.chartOptionsDetail.xaxis.labels.style.colors.push('#3498db');
            this.$data.chartOptionsDetail.colors.push('#3498db');
          }else if(dataWarna[i] === "Cukup"){
            this.$data.chartOptionsDetail.xaxis.labels.style.colors.push('#2ecc71');
            this.$data.chartOptionsDetail.colors.push('#2ecc71');
          }else if(dataWarna[i] === "Kurang"){
            this.$data.chartOptionsDetail.xaxis.labels.style.colors.push('#ff9f43');
            this.$data.chartOptionsDetail.colors.push('#ff9f43');
          }else{
            this.$data.chartOptionsDetail.xaxis.labels.style.colors.push('#e74c3c');
            this.$data.chartOptionsDetail.colors.push('#e74c3c');
          }
            
        }
        this.$refs.sampleDetail.updateOptions({
            // seriesDetail: [{
            //   data: dataSeries
            // }],
        })
      })
      .catch( error => {
        console.log("error")
        this.seriesDetail[0].data = []
        this.$data.chartOptionsDetail.xaxis.categories= []
        this.$data.chartOptionsDetail.colors = []
        this.$refs.sampleDetail.updateOptions({
            seriesDetail: [{
              data: []
            }],
        })
      })
    },
    downloadExcel(){
    if(this.pengguna.hak_akses == "mabes"){
      this.downloadExcell = this.url+'/stok_export?tipe_wilayah=semua'
    }
    
  },
  },
  
  created (){
    this.loadData()
    if(this.pengguna.hak_akses == "mabes"){
      console.log("ini mabes")
    }else if(this.pengguna.hak_akses == "polda"){
      this.getPolda(this.pengguna.id_prov)
    }else if(this.pengguna.hak_akses == "polres"){
      this.getPolres(this.pengguna.id_kab)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
